<template>
  <div></div>
</template>

<script>
import {SET_LANGUAGES} from "@/core/services/store/languages.module";
import JwtService from "@/core/services/jwt.service";
import Vue from "vue";

export default {
  mounted() {
    // this.getLanguages();
  },
  computed: {
    loggedIn() {
      return !!JwtService.getToken();
    }
  },
  methods: {
    getLanguages() {
      let url = process.env.VUE_APP_API_TENANT;
      if (this.$store.getters.userType === "admin") {
        url = process.env.VUE_APP_API_ADMIN;
      }
      Vue.axios.get(url + '/languages').then(response => {
        this.$store.dispatch(SET_LANGUAGES, response.data.data);
      })
    }
  }
}
</script>
